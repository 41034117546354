import React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import { galleryHolder } from './gallery.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const GalleryRow = ({ row }) => {
  let tic = 'single';

  if (row.length > 1) tic = 'diptic';
  if (row.length > 2) tic = 'triptych';

  return (
    <ul className={`galleryRowHolder ${tic}`}>
      {row.map((image, index) => {
        const imageSrc = getImage(image);
        return (
          <li className="galleryImage" key={index}>
            <GatsbyImage image={imageSrc} alt={image.name} />
          </li>
        );
      })}
    </ul>
  );
};

export default function GalleryPage({ pageContext }) {
  return (
    <article className={galleryHolder}>
      <h2>{pageContext.book}</h2>
      <div className="description" dangerouslySetInnerHTML={{ __html: pageContext.description }} />
      <ul className="gallery">
        {pageContext.galleryRows.map((row, index) => (
          <li className="galleryRow" key={index}>
            <GalleryRow key={index} row={row} />
          </li>
        ))}
      </ul>
    </article>
  );
}

export const Head = () => <Seo />;
